import { render, staticRenderFns } from "./ChangeOrder.vue?vue&type=template&id=3ede9533&scoped=true"
import script from "./ChangeOrder.vue?vue&type=script&lang=js"
export * from "./ChangeOrder.vue?vue&type=script&lang=js"
import style0 from "./ChangeOrder.vue?vue&type=style&index=0&id=3ede9533&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ede9533",
  null
  
)

export default component.exports